<template>
	<div class="relative w-full text-white md:w-[400px]">
		<div v-if="!submissionSuccessful">
			<p class="font-light text-white">{{ text }}</p>

			<form @submit.prevent="submitHandler">
				<div class="mb-1 mt-2.5 grid grid-cols-2 gap-2">
					<input
						v-model="firstName"
						type="text"
						placeholder="First name"
						required
						:class="[
							'h-12 rounded-t border-b-2 border-b-white bg-white/10 pl-6 placeholder:text-white/70',
							'focus-visible:ring focus-visible:ring-primary',
						]"
					/>
					<input
						v-model="lastName"
						type="text"
						placeholder="Last name"
						required
						:class="[
							'h-12 rounded-t border-b-2 border-b-white bg-white/10 pl-6 placeholder:text-white/70',
							'focus-visible:ring focus-visible:ring-primary',
						]"
					/>
					<div class="col-span-2 flex flex-nowrap">
						<input
							v-model="email"
							type="text"
							placeholder="Email"
							required
							:class="[
								'h-12 w-full rounded-t border-b-2 border-b-white bg-white/10 pl-6 placeholder:text-white/70',
								'focus-visible:ring focus-visible:ring-primary',
							]"
						/>

						<button
							aria-label="Newsletter sign up button"
							type="submit"
							:class="[
								'flex h-12 w-12 items-center justify-center rounded-tr border-b-2 border-b-white bg-primary hover:bg-primary-hover',
								'focus-visible:ring focus-visible:ring-primary focus-visible:ring-offset-2',
							]"
						>
							<ArrowPathIcon
								v-if="isSubmitting"
								class="size-6 animate-spin stroke-2 text-text"
							/>
							<IconsArrowRight v-else class="w-8" />
						</button>
					</div>
				</div>
				<div class="flex gap-1.5 text-xs">
					<input
						id="age-verification"
						type="checkbox"
						required
						class="accent-primary focus-visible:rounded-sm focus-visible:ring focus-visible:ring-primary"
					/>
					<label for="age-verification"
						>I am 21+ years of age and agree to receive marketing emails</label
					>
				</div>

				<div class="mt-2 text-[0.625rem] text-white">
					This site is protected by reCAPTCHA and the Google
					<a
						href="https://policies.google.com/privacy"
						class="text-primary underline hover:text-primary-hover focus:ring-1 focus-visible:ring-primary"
						>Privacy Policy</a
					>
					and
					<a
						href="https://policies.google.com/terms"
						class="text-primary underline hover:text-primary-hover focus:ring-1 focus-visible:ring-primary"
						>Terms of Service</a
					>
					apply.
				</div>
			</form>
		</div>

		<div v-else class="flex text-primary">
			Thank you! You are now signed up for our newsletter.
		</div>
	</div>
</template>

<script setup lang="ts">
import handleFrontendError from '../../utils/handleFrontendError';
import useRecaptchaV3 from '~/composables/recaptchaV3';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';

const { executeV3recaptcha } = useRecaptchaV3();

defineProps({
	text: { type: String, default: 'Sign up to receive our emails.' },
});

const firstName = ref('');
const lastName = ref('');
const email = ref('');

const isSubmitting = ref(false);
const submissionSuccessful = ref(false);

async function submitHandler() {
	try {
		isSubmitting.value = true;
		const token = await executeV3recaptcha();
		await $fetch('/api/alpineiq/newsletter-signup', {
			method: 'POST',
			body: {
				firstName: firstName.value,
				lastName: lastName.value,
				email: email.value,
				captchaResponse: token,
			},
		});

		submissionSuccessful.value = true;
	} catch (error) {
		handleFrontendError(error);
	} finally {
		isSubmitting.value = false;
	}
}
</script>
